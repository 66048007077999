<template>
    <footer class="footer">
        <transition name="simple-transition">
            <drop-down v-if="!loading" id="footer-languages-dropdown" class="dropup">
                <template slot="label"> {{ langLabel }} <i class="nova-chevron-up caret"></i></template>
                <template slot="content">
                    <a 
                        v-for="lang in languages"
                        :key="lang.Language__"
                        :href="'/l/' + lang.Language_Lowercase + '/#' + $router.currentRoute.path + ($router.currentRoute.query.g ? '?g=' + $router.currentRoute.query.g: '')"
                    >{{ lang.Local_Name }}</a>
                </template>
            </drop-down>
        </transition>
        <a class="btn" :href="'https://hub.atonline.com/l/'+activeLang+'/law.html'" target="_blank">{{ $t('law_title') }}</a>
        <template v-if="!nocontact">
            <router-link class="btn" :to="{ name: 'Contact' }"><i class="nova-chat-bubble-square-smiley"></i> {{ $t('contact') }}</router-link>
        </template>
        <a
            v-if="activeLang === 'ja-JP'"
            href="build/atOnline_Drive_manual.pdf"
            class="btn"
        >マニュアル</a>
        <span class="mentions">©{{ (new Date).getFullYear() }} Tristan Technologies - {{ $t('all_rights_reserved') }}</span>
    </footer>
</template>

<style lang="scss">
    .footer {
        position: absolute;
        bottom: 0; right: 0;
        padding-right: 2rem;
        background-color: #f4f1f5;
        font-size: .8em;
        & > * {
            display: inline-block;
            vertical-align: middle; 
        }
        & > *,
        .dropdown-wrapper > * {
            margin: 0;
        }
        .dropdown-wrapper {
            display: inline-block;
        }
        .mentions {
            margin-left: 2rem;
        }
        // @media (min-width: 1088px) {
        //     margin-right: -64px;
        // }
    }
</style>

<script>
import DropDown         from './../../components/others/DropDown.vue';
import { mapGetters }   from 'vuex';

export default {
    components: { 
        'drop-down': DropDown
    },
    data () {
        return {
            activeLang  : null, 
            languages   : null,
            langLabel   : "",
            loading     : false
        };
    },
    props: ['nocontact'],
    computed: {
        ...mapGetters([
            'getContextLang'
        ])
    },
    watch: {
        activeLang (val) {
            this.langLabel = (this.languages.find(elem => elem.Language_Lowercase == val)).Local_Name;
        }
    },
    beforeMount () {
        this.loading    = true;
        
        FW.rest('Language:local', 'GET').done(data => {
            if (data.result == "success") {
                this.loading    = false;
                this.languages  = data.data;
                this.activeLang = this.getContextLang; 
            } else 
                this.displayErrorAlert(data);
        }).fail(data => {
            this.displayErrorAlert(data);
        }).always(data => {
            this.loading = false;
        });
    }
}
</script>
