var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      !_vm.landing && !_vm.loading
        ? _c(
            "button",
            {
              staticClass: "btn back-btn",
              attrs: { type: "button" },
              on: { click: _vm._initLogin }
            },
            [
              _c("i", { staticClass: "nova-arrow-left" }),
              _vm._v(" \n        " + _vm._s(_vm.$t("back")) + "\n    ")
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h2",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.user,
              expression: "!user"
            }
          ],
          staticClass: "align-center"
        },
        [_vm._v(_vm._s(_vm.message || _vm.$t("user_flow_input_email")))]
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "simple-transition", mode: "out-in" } },
        [
          _vm.loginStatus
            ? _c("div", { staticClass: "login-status" }, [
                _c("p", { class: _vm.loginStatus.result }, [
                  _vm._v(_vm._s(_vm.loginStatus.message))
                ])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "panel-transition", mode: "out-in" } },
        [
          _c(
            "form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading"
                }
              ],
              ref: "authForm",
              staticClass: "panel",
              attrs: { action: "", method: "POST" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.process($event)
                },
                keypress: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.process($event)
                }
              }
            },
            [
              _vm.user
                ? _c("div", { staticClass: "avatar-wrapper" }, [
                    _vm.user.Profile && _vm.user.Profile.Media_Image
                      ? _c("img", {
                          staticClass: "avatar",
                          attrs: { src: _vm.user.Profile.Media_Image.Url }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("h2", [_vm._v(_vm._s(_vm.user.Display_Name))])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.elems, function(e, i) {
                return [
                  e.type == "label"
                    ? _c(
                        "label",
                        { key: i },
                        [
                          e.link
                            ? [
                                _c("a", { attrs: { href: e.link } }, [
                                  _vm._v(_vm._s(e.label))
                                ])
                              ]
                            : [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(e.label) +
                                    "\n                    "
                                )
                              ]
                        ],
                        2
                      )
                    : e.type == "image"
                    ? _c("avatar", {
                        key: i,
                        attrs: {
                          avatarUrl: "img/default-avatar.svg",
                          prepare: _vm.prepareAvatar,
                          save: _vm.saveAvatar
                        },
                        on: { saved: _vm.avatarSaved }
                      })
                    : e.type == "checkbox"
                    ? _c("div", { key: i, staticClass: "checkbox" }, [
                        _c("input", {
                          attrs: {
                            id: e.name + "-" + i,
                            name: e.name,
                            type: "checkbox",
                            value: "1",
                            required: ""
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: e.name + "-" + i } },
                          [
                            e.link
                              ? [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: e.link, target: "_blank" }
                                    },
                                    [_vm._v(_vm._s(e.label))]
                                  )
                                ]
                              : [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(e.label) +
                                      "\n                        "
                                  )
                                ]
                          ],
                          2
                        )
                      ])
                    : e.type != "oauth2" && e.type != "checkbox"
                    ? _c(
                        "div",
                        {
                          key: i,
                          staticClass: "input-group pretty-placeholder"
                        },
                        [
                          _c("label", { attrs: { for: e.name + "-" + i } }, [
                            _vm._v(_vm._s(e.label))
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            attrs: {
                              id: e.name + "-" + i,
                              type: e.type,
                              name: e.name,
                              value: "",
                              spellcheck: "false",
                              placeholder: " ",
                              required: ""
                            }
                          }),
                          _vm._v(" "),
                          e.type == "email"
                            ? _c(
                                "label",
                                {
                                  staticClass: "placeholder",
                                  attrs: { for: e.name + "-" + i }
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon nova-envelope-1"
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("placeholder_email")))
                                  ])
                                ]
                              )
                            : e.type == "password"
                            ? _c(
                                "label",
                                {
                                  staticClass: "placeholder",
                                  attrs: { for: e.name + "-" + i }
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon nova-key-hole-1"
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("placeholder_password"))
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ]
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "third-party-btns" },
                [
                  _vm._l(_vm.elems, function(e, i) {
                    return e.type == "oauth2"
                      ? [
                          _c(
                            "button",
                            {
                              key: i,
                              class: "third-party-btn btn " + e.info.Token_Name,
                              attrs: { type: "button", title: e.info.Name },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.typeOfCo(e.id)
                                }
                              }
                            },
                            [_vm._v(" ")]
                          )
                        ]
                      : _vm._e()
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.session
                ? _c("input", {
                    attrs: { type: "hidden", name: "session" },
                    domProps: { value: _vm.session }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn primary btn-next",
                  attrs: { type: "submit" }
                },
                [_vm._v(_vm._s(_vm.$t("next")))]
              )
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "panel-transition", mode: "out-in" } },
        [_vm.loading ? _c("loader") : _vm._e()],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }