export default {
    $t(key, args = {}) { 
        /** 
         * args can be a string, in which case it's considered as a fallback.
         * 
         * args can be an object containing optionnally :
         *      fallback     = a fallback value if the key is not found
         *      qty          = a number representing a quantity (useful for singular/plural or "0 = no apple | 1 = an apple | 2 = the apples" etc.)
         *      variables    = variables with a string value ; if the variable name is found in the translation between % (ex. "%var%"), it will be replaced by the given value.  
         * 
         */

         // Check if key exists in catalog
        if (this.$store.state.i18nCatalog[key]) {
            var t = this.$store.state.i18nCatalog[key];

            // Check if there are pipes in the translation 
            t = this._handleQty(t, args);

            // Inject variables
            t = this._injectVariables(t, args);
            
            return this._htmlDecode(t);
        } 
        var fallback = args.fallback ? args.fallback : typeof args == 'string' ? args : key;
        return this._htmlDecode(fallback);
    },
    _injectVariables(value, args) {
        // Check if there are variables
        var variables = Object.assign({}, args);
                    
        if (variables.qty)
            delete variables['qty'];
        if (variables.fallback)
            delete variables['fallback'];

        for(var v in variables) 
            value = value.replace(new RegExp('%' + v + '%', 'g'), variables[v]);

        return value;
    },
    _handleQty(value, args) {
        var tArr = value.split('|');
        if (tArr.length > 1) {
            // Check if quantity is given
            if (!isNaN(args.qty)) {
                for(var i = 0; i < tArr.length; i++) {
                    var toMatch = '{' + args.qty + '}';
                    // Check if exact quantity exists in translation
                    if (tArr[i].indexOf(toMatch) >= 0) {
                        value = tArr[i].replace(toMatch, '');
                        break;
                    // Check if minimum qty exists in translation
                    } else if (tArr[i].match(/{(.*)\+}/)) {
                        var minQtyForThis = tArr[i].match(/{(.*)\+}/).pop();
                        if (args.qty >= minQtyForThis) {
                            value = tArr[i].replace('{' + minQtyForThis + '+}', '');
                            break;
                        }
                    }
                }
            } else {
                value = tArr[0];
            }
        }
        return value;
    },
    _htmlDecode(input){
        var e = document.createElement('div');
        e.innerHTML = input;
        // handle case of empty input
        return e.childNodes.length === 0 ? input : e.childNodes[0].nodeValue;
      }
      
};