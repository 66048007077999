<template>
    <div class="dropdown-wrapper">
        <button 
            @click="handleClick"
            :class="{ 'active': show }"
            ref="btn"
        ><slot name="label"></slot></button>
        <transition name="drop-down">
            <div
                class="dropdown"
                v-show="show"
            >
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>

<style lang="scss">
@import './../../../assets/css/_variables.scss';

.dropdown-wrapper {
    position:relative;
    & > button {
        & > .caret {
            display: inline-block;
            perspective: 800px;
            transform: rotate3d(1,0,0,0deg);
            transition: transform .25s linear;       
        }
        &.active {
            background-color: $color-bg;
            color: $color-text;
            & > .caret {
                transform: rotate3d(1, 0, 0, 180deg);
            }
        }
    }
    & > .dropdown {
        position: absolute;
        top: calc(100% + .5rem); left: 0;
        min-width: 20rem; width: auto; max-width: 100vw;
        height: auto;
        max-height: calc(100vh - 10rem);
        border: .1rem solid $color-menu-bg;
        background-color: $color-bg;
        z-index: 2;
        color: $color-text;
        box-shadow: 0 0 25px rgba(40,47,60,.05), 0 20px 25px rgba(40,47,60,.05), 0 3px 4px rgba(40,47,60,.05);
        transform-origin: top center;
        overflow-x: hidden;
        overflow-y: auto; 
        ul {
            margin: 0; padding: 0;
            list-style-type: none;
        }
        a:not(.pretty-link) { 
            position: relative;
            box-sizing: border-box;
            display: inline-block;
            width: 100%;
            margin: 0;
            padding: .5rem 2rem .5rem 1rem;
            color: $color-text; 
            &:after {
                content: "\e945";
                position: absolute;
                top: 50%; right: -1rem;
                margin: 0;

                font-family: 'nova-linear' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;

                opacity: 0;
                transform: translate3d(0, -50%, 0);
                transition: right .2s $transition-easing,
                            opacity .2s linear,
                            margin-left 0s linear .2s;
            }
            &:hover,
            &:focus {
                color: #fff;
                background: linear-gradient(to right, $gradient-base);
                &:after {
                    opacity: 1;
                    right: 2rem;
                    margin-right: -1rem;
                    transition: margin-right .2s $transition-easing,
                                opacity .2s linear;
                }
            }
        }
    }
    &.dropup > .dropdown {
        top: unset;
        bottom: calc(100% + .5rem);
        transform-origin: bottom center;
    }
    .drop-down-enter-active {
        transition: opacity .2s linear, 
                    transform .2s $transition-easing;
        & > * {
            transition: opacity .2s linear .1s,
                        transform .2s $transition-easing .1s;
        }
    }
    .drop-down-leave-active {
         transition: unset;
    }
    .drop-down-enter, .drop-down-leave-to {
        opacity: .5;
        transform: scale3d(1, 0, 1);
        & > * {
            opacity: 0;
            transform: translate3d(0, -2rem, 0);
        }
    }
} 
.right .dropdown-wrapper > .dropdown {
    left: unset;
    right: 0;
    text-align: right;
    a {
        padding-right: 4rem;
    }
}
</style>

<script type="javascript">
export default {
    data () {
        return {
            show: false
        };
    }, 
    props: ['subject'],
    methods: {
        closeDropdownsIfNeeded(event) { // Please refer to the call of this method in mounted()

            var preventClosing  = false; 
            var node            = event.target;
            
            while (node != document.body) {
                if (node) {
                    if (event && node.classList && node.classList.contains('dropdown-prevent-closing')) {
                        preventClosing = true;
                        break;
                    }
                    node = node.parentNode;
                } else 
                    break;
            }

            if (preventClosing) {
                event.stopPropagation();
                return true;
            }

            this.show = false; 
            return true;
        },
        handleClick() {
            this.show = !this.show;
        }
    }, 
    mounted () {
        // simpler solutions could have been implemented, but this one avoids putting an overlay (-1 click) while preventing dropdowns to close themselves whenever you click inside them.
        document.addEventListener('click', e => { 
            if (e.target != this.$refs.btn) {
                this.closeDropdownsIfNeeded(e);
            }
        });
        EventBus.$on('dropdown:show', (arg) => {
            if (this.subject == arg) { 
                setTimeout(() => {
                    this.show = true;
                    this.$refs.btn.focus();
                }, 100);
            }
        });
    }
}
</script>
