import Vue                  from 'vue';

import Login                from './../../templates/components/authentication/Login/Login.vue';
import store                from './_store.js';
import translationUtility   from './_translationUtility.js';
import { mapActions }       from 'vuex';

Vue.mixin({
    methods: Object.assign({
        isMSIE () {
            return (navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./));
        }
    }, translationUtility)
});

window.EventBus = new Vue();

window.vm = new Vue({ 
    delimiters: ["${","}"], 
    el: '#app', 
    store: store, 
    data() {
        return {};
    }, 
    methods: {
        ...mapActions([
            'initI18nCatalog'
        ])
    },
    render: h => h(Login),
    beforeMount () {
        this.initI18nCatalog(window.i18nCatalog);
        delete window.i18nCatalog;

    }
});