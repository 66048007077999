
import Footer           from './../../../layout/Footer/Footer.vue';
import Loader           from './../../others/Loader.vue';
import RegisterAvatar   from './RegisterAvatar.vue';

export default {
    components: {
        'avatar'        : RegisterAvatar, 
        'bottom-footer' : Footer, 
        'loader'        : Loader
    },
    data () {
        return {
            activePanel     : "login", // login, loading, challenge 
            elems           : null, 
            landing         : true, 
            lastStep        : null, 
            loginStatus     : null, 
            loading         : false,
            isRegistration  : false, 
            message         : "", 
            prepareAvatar   : false, 
            saveAvatar      : false, 
            session         : null, 
            user            : null
        };
    },
    methods: {
        avatarSaved () {
            this.redirect();
        }, 
        checkIfInvalidStep (data) {
            if (this.lastStep && 
                this.lastStep.length === data.data.req.length && 
                this.lastStep.sort().every(function(value, index) { 
                    return value === data.data.req.sort()[index];
                })
            ) {
                this.loginStatus = {
                    result  : 'error',
                    message : this.$t('error_invalid_login_or_password')
                } 
            }
        },
        process () {
            this.landing = false;
            if (this.isRegistration) {
                this.prepareAvatar = true;
                setTimeout(() => {
                    this.loading = true;
                }, 250);
            } else 
                this.loading = true;

            this.loginStatus    = null;
            var formData        = new FormData(this.$refs.authForm);
            formData.append('return_to', this._fixReturnToUrl(this._findGetParameter("return_to") || '/'));
            FW.rest('User:flow', 'POST', formData)
                .done(data => {
                    this.elems      = [];
                    
                    if (!data.data.complete) {
                        this.isRegistration = data.data.fields && data.data.fields.length > 0 && data.data.fields.some(el => 
                            el.name == 'profile_pic'
                        );

                        // if (!this.isRegistration)
                        //     this.checkIfInvalidStep(data);

                        this.elems      = data.data.fields;

                        
                        this.$nextTick(() => {
                            this.$refs.authForm.querySelectorAll('input[type="text"]').forEach(el => {
                                el.value = "";
                            });
                        });

                        this.message    = data.data.message;
                        this.lastStep   = data.data.req;
                        if (data.data.user) 
                            this.user       = data.data.user;
                        if (data.data.session)
                            this.session    = data.data.session;
                        this.loading    = false; 

                    } else {
                        if (this.isRegistration) {                
                            this.saveAvatar = true;
                            EventBus.$emit('registration:avatar:save'); // because VueJS SHITTY INCONSISTENCIES, this time, contrary to this.pepareAvatar, reactivity seems to be not working on this.saveAvatar WHICH IS A FUCKING BOOLEAN FOR FUCK SAKE
                        } else
                            this.redirect(); 
                        
                    }
                })
                .fail(data => {
                    this.loginStatus = {
                        result  : "error",
                        message : data.responseJSON.error
                    };
                    this._initLogin(true);
                })
        },
        redirect () {
            this.loginStatus    = {
                result  : 'success',
                message : this.$t('login_success')
            } 
            window.location = this._fixReturnToUrl(this._findGetParameter("return_to") || '/');
        }, 
        typeOfCo(consumer_id){
            var formated        = this._fixReturnToUrl(this._findGetParameter("return_to") || '/');
            this.loading        = true;
            this.activePanel    = "loading"; 
            FW.rest("User:flow", "GET", {
                'oauth2' : consumer_id
            })
                .done(data => {
                    this.loading     = false;
                    this.loginStatus = {
                        result  : "success",
                        message : this.$t('redirecting_to_service')
                    };
                    
                    window.location.href = data.data.url;
                }).fail(data => {
                    this.loginStatus = {
                        result  : "error",
                        message : data.responseJSON.error
                    };
                    this._initLogin(true);
                });
        },
        _findGetParameter(parameterName) {
            var result = null,
                tmp = [];
            location.search
                .substr(1)
                .split("&")
                .forEach(function (item) {
                  tmp = item.split("=");
                  if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
                });
            return result;
        }, 
        _fixReturnToUrl (url) {
            var fixed   = decodeURIComponent(url);
            var res     = url.match(/^(http?s\:\/)\w+/gi);
            if(res) {
                var idx = url.indexOf('/');
                fixed   = url.slice(0, idx) + "/" + url.slice(idx);
            }
            return fixed;
        },
        _initLogin (preserveStatus = false) {
            this.isRegistration = false;
            this.lastStep       = null; 
            this.loading        = true;
            if (!preserveStatus)
                this.loginStatus = null;
            this.landing        = true;
            this.session        = null;
            this.user           = null;
            var params          = {};
            var session         = this._findGetParameter("session");
            if (session)
                params.session  = session;
            FW.rest('User:flow', 'GET', params)
                .done(data => {
                    if (data.result == 'success') {
                        if (!data.data.complete) {
                            this.elems      = data.data.fields;
                            this.message    = data.data.message;
                            this.loading    = false;
                        } else {
                            this.redirect(); 
                        }
                    }
                })
                .fail(data => {
                    this.loginStatus = {
                        result  : "error",
                        message : data.responseJSON.error
                    };
                    this.loading = false;
                });
        }
    },
    beforeMount () {
        this._initLogin();
    }
};