import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        allServices     : false,
        contextLang     : false, 
        countryList     : false,
        driveList       : false, 
        i18nCatalog     : false, 
        pricingPlans    : false, 
        sharedItems     : false, 
        trashbin        : null, 
        user            : false,
        userGroup       : false, 
        userServices    : false
    }, 
    getters: {
        getAllServices  : state => state.allServices,
        getContextLang  : state => state.contextLang, 
        getCountryList  : state => state.countryList,
        getDriveList    : state => state.driveList,
        getI18nCatalog  : state => state.getI18nCatalog,
        getPricingPlans : state => state.pricingPlans, 
        getSharedItems  : state => state.sharedItems,
        getTrashbin     : state => state.trashbin, 
        getUser         : state => state.user,
        getUserAvatar   : state => state.user && state.user.Profile.Drive_Item ? state.user.Profile.Drive_Item.Media_Image : {}, 
        getUserEmail    : state => state.user.Email, 
        getUserGroup    : state => state.userGroup, 
        getUserName     : state => state.user.Profile.Display_Name,
        getUserServices : state => state.userServices,

        getCountryById  : (state) => (id) => {
            return state.countryList[id];
        }, 
        getDriveById    : (state) => (id) => {
            if (state.driveList instanceof Array)
                return state.driveList.find(elem => elem.Drive__ == id);
            else 
                return null;
        }, 
        getGroupById    : (state) => (id) => {
            return state.userGroup[id];
        }, 
        getPricingPlanById: (state) => (id) => {
            if (state.pricingPlans instanceof Array)
                return state.pricingPlans.find(el => el.SKU == id);
            else 
                return null;
        },
        getSharedItemById: (state) => (id) => {
            return state.sharedItems[id];
        }
    },
    mutations: {
        ADD_DRIVE: (state, payload) => {
            if (state.driveList) 
                state.driveList.push(payload);
        }, 
        DELETE_DRIVE: (state, payload) => {
            if (!(state.driveList instanceof Array))
                return false;
            
            var idx = state.driveList.findIndex(el => el.Drive__ == payload);
            if (idx >= 0) 
                state.driveList.splice(idx, 1);
            else 
                return false; 

            return true;
        },
        INIT_ALL_SERVICES: (state, payload) => {
            if (!state.allServices) 
                state.allServices = payload;
        },
        INIT_CONTEXT_LANG: (state, payload) => {
            if (!state.contextLang)
                state.contextLang = payload; 
        },
        INIT_COUNTRY_LIST: (state, payload) => {
            if (!state.countryList) 
                state.countryList = payload;
        },
        INIT_DRIVE_LIST: (state, payload) => {
            if (!state.driveList)
                state.driveList = payload; 
        },
        INIT_I18N_CATALOG: (state, payload) => {
            if (!state.i18nCatalog) 
                state.i18nCatalog = payload;
        },
        INIT_PRICING_PLANS: (state, payload) => {
            if (!state.pricingPlans)
                state.pricingPlans = payload;
        },
        INIT_SHARED_ITEMS: (state, payload) => {
            if (!state.sharedItems) 
                state.sharedItems = payload;
        }, 
        INIT_USER: (state, payload) => {
            if (!state.user) {
                state.user = payload;
            }
        },
        INIT_USER_GROUP: (state, payload) => {
            if (!state.userGroup) 
                state.userGroup = payload;
        },
        INIT_USER_SERVICES: (state, payload) => {
            if (!state.userServices) 
                state.userServices = payload;
        },
        SET_TRASHBIN: (state, payload) => {
            state.trashbin = payload;
        },
        UPDATE_DRIVE_NAME: (state, payload) => {
            var index = state.driveList.findIndex(elem => elem.Root.Drive_Item__ == payload.Root.Drive_Item__);
            if (index >= 0) 
                state.driveList[index].Name = payload.Name;
        },
        UPDATE_SHARED_ITEM_NAME: (state, payload) => {
            var index = state.sharedItems.findIndex(elem => elem.Drive_Item__ == payload.Drive_Item__);
            if (index >= 0) 
                state.sharedItems[index].Name = payload.Name;
        },
        UPDATE_USER_META: (state, payload) => {
            state.user.Meta[payload.name] = payload.value;
        }
    },
    actions: {
        addDrive        : (context, payload) => { context.commit('ADD_DRIVE', payload); }, 

        deleteDrive     : (context, payload) => { context.commit('DELETE_DRIVE', payload); }, 

        initAllServices : (context, payload) => { context.commit('INIT_ALL_SERVICES', payload); },
        initContextLang : (context, payload) => { context.commit('INIT_CONTEXT_LANG', payload); }, 
        initCountryList : (context, payload) => { context.commit('INIT_COUNTRY_LIST', payload); },
        initDriveList   : (context, payload) => { context.commit('INIT_DRIVE_LIST', payload); },
        initI18nCatalog : (context, payload) => { context.commit('INIT_I18N_CATALOG', payload); },
        initPricingPlans: (context, payload) => { context.commit('INIT_PRICING_PLANS', payload); },
        initSharedItems : (context, payload) => { context.commit('INIT_SHARED_ITEMS', payload); },
        initUser        : (context, payload) => { context.commit('INIT_USER', payload); },
        initUserGroup   : (context, payload) => { context.commit('INIT_USER_GROUP', payload); },
        initUserServices: (context, payload) => { context.commit('INIT_USER_SERVICES', payload); },

        setTrashbin     : (context, payload) => { context.commit('SET_TRASHBIN', payload); }, 

        updateDriveName     : (context, payload) => {
            context.commit('UPDATE_DRIVE_NAME', payload);
        },
        updateSharedItemName: (context, payload) => {
            context.commit('UPDATE_SHARED_ITEM_NAME', payload);
        },
        updateUserMeta      : (context, payload) => {
            context.commit('UPDATE_USER_META', payload);
        }
    }
})
