<template>
    <div class="coffee-cup"></div>
</template>

<style lang="scss">
@import './../../../assets/css/_variables.scss';

/* coffee cup */
.coffee-cup{
    width: 4rem;
    height: 4.8rem;
    border: .2rem $color-base-2 solid;
    border-radius: 0 0 1rem 1rem;
    position: relative;
    margin: 7.2rem auto;
}
.coffee-cup:after, 
.coffee-cup:before {
    position: absolute;
    content: "";
}
.coffee-cup:after {
    width: 1rem;
    height: 2.4rem;
    border: .2rem $color-base-2 solid;
    border-left: none;
    border-radius: 0 4rem 4rem 0;
    left: 4rem;
}
.coffee-cup:before {
    width: .2rem;
    height: 1.2rem;
    background-color: $color-base-2;
    top: -2rem;
    left: .8rem;
    box-shadow: 1rem 0 0 0 $color-base-2,
                1rem -1rem 0 0 $color-base-2,
                2rem 0 0 0 $color-base-2;
    animation: steam 1s linear infinite alternate;
}
@keyframes steam {
    0%{height: 0}
    100%{height: 1.2rem;}            
}
</style>

<script>
export default {
    data () {
        return {};
    }
}
</script>
